<template>
  <div class="p-4 block">
    <div class="font-h font-semibold text-lg text-primary">Properties</div>
    <div class="flex mt-4.5 items-center">
      <MusicNameIcon class="mr-2.5 musicIcon"/>
      <div class="text-sm text-gray-600">{{blockmodeConfig.name}}</div>
    </div>

    <div v-if="shouldRender" class="mt-5">
      <div class="flex text-sm mb-4 text-gray-500 items-center justify-between">
        <div>Volume</div>
        <div>
          {{Math.round(blockmodeConfig.volume)}}%
        </div>
      </div>
      <Slider
        :max="100"
        :min="0"
        :value="blockmodeConfig.volume"
        :setValue="volumeChanged"
       />
    </div>

    <div v-if="shouldRender" class="mt-7.5">
      <div class="flex text-sm mb-4 text-gray-500 items-center justify-between">
        <div>Fade In</div>
        <div>
          {{(blockmodeConfig.fadeInMs/1000).toFixed(1)}}s
        </div>
      </div>
      <Slider
        :max="fadeXLimit"
        :min="0"
        :value="blockmodeConfig.fadeInMs"
        :setValue="fadeInChanged"
       />
    </div>

    <div v-if="shouldRender" class="mt-7.5">
      <div class="flex text-sm mb-4 text-gray-500 items-center justify-between">
        <div>Fade Out</div>
        <div>
          {{(blockmodeConfig.fadeOutMs/1000).toFixed(1)}}s
        </div>
      </div>
      <Slider
        :max="fadeXLimit"
        :min="0"
        :value="blockmodeConfig.fadeOutMs"
        :setValue="fadeOutChanged"
       />
    </div>
  </div>
</template>

<script>
import debounce from 'lodash/debounce'
import { mapGetters, mapActions } from 'vuex'
import Slider from '@/components/base/inputs/Slider.vue'
import MusicNameIcon from '@/components/base/icons/MusicName.vue'

export default {
  name: 'BlockModePanel',
  components: {
    MusicNameIcon,
    Slider
  },
  computed: {
    ...mapGetters({
      blockmodeConfig: 'blockmode/blockmodeConfig'
    }),
    shouldRender: function() {
      return this.blockmodeConfig.toMs - this.blockmodeConfig.fromMs > 0
    },
    fadeXLimit: function() {
      return 0.45 * (this.blockmodeConfig.toMs - this.blockmodeConfig.fromMs)
    }
  },
  methods: {
    ...mapActions({
      updateConfig: 'blockmode/update',
      close: 'blockmode/close',
      deleteMusic: 'blockmode/deleteMusic',
      replaceMusic: 'blockmode/replaceMusic'
    }),
    update: function(payload) {
      this.updateConfig({
        ...payload,
        blockmodeUpdate: true
      })
    },
    volumeChanged: debounce(function(volume) {
      this.update({ volume: volume < 1 ? 1 : volume })
    }, 400),
    fadeInChanged: debounce(function(fadeInMs) {
      this.update({ fadeInMs })
    }, 400),
    fadeOutChanged: debounce(function(fadeOutMs) {
      this.update({ fadeOutMs })
    }, 400)
  }

}
</script>

<style scoped>
.musicIcon {
  width: 23px;
  height: 23px;
}

</style>
