<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="23"
    height="23"
    viewBox="0 0 23 23"
    fill="none"
  >
    <rect x="2" y="2" width="19" height="19" rx="2.375" fill="white"/>
    <rect x="1.40625" y="1.40625" width="20.1875" height="20.1875" rx="2.96875" stroke="black" stroke-opacity="0.14" stroke-width="1.1875"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M10 8.37838C10 8.13396 10.1767 7.92536 10.4178 7.88518L15.4178 7.05185C15.7226 7.00105 16 7.23608 16 7.54505V7.95482V8.95482V13.3993C16 14.2587 15.3287 14.9548 14.5 14.9548C13.6712 14.9548 13 14.2587 13 13.3993C13 12.5398 13.6712 11.8437 14.5 11.8437C14.7737 11.8437 15.0288 11.9254 15.25 12.0576V9.10482L11 9.95482V14.9548C11 16.0598 10.105 16.9548 9 16.9548C7.895 16.9548 7 16.0598 7 14.9548C7 13.8498 7.895 12.9548 9 12.9548C9.365 12.9548 9.705 13.0598 10 13.2298V8.37838Z" fill="#8338EC"/>
  </svg>
</template>

<script>
export default {
  name: 'MusicName'
}
</script>

<style lang="css" scoped>

</style>
