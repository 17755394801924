<template>
  <VueSlider
    v-model="progress"
    style="height:2px;padding: 0;width: 100%;"
    :railStyle="{'cursor': 'pointer', 'background-color': '#D1D5DB'}"
    :processStyle="{'background-color': '#000228'}"
    :tooltip="'none'"
    :interval="0.1"
  >
    <template v-slot:dot="">
      <div :class="'custom-dot'" />
    </template>
  </VueSlider>
</template>

<script>
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/default.css'

export default {
  components: {
    VueSlider
  },
  props: {
    value: {
      type: Number,
      default: 0
    },
    min: {
      type: Number,
      default: 0
    },
    max: {
      type: Number,
      default: 100
    },
    setValue: {
      type: Function,
      default: () => null
    }
  },
  computed: {
    range: function() {
      return this.max - this.min
    },
    progress: {
      get() {
        return 100 * this.value / this.range
      },
      set(newVal) {
        this.setValue(this.min + (this.range * newVal / 100))
      }
    }
  }
}
</script>

<style lang="less" scoped>
.custom-dot {
  width: 14px;
  height: 14px;
  border-radius: 50%;
  box-shadow: 0px 0px 14px -3.5px rgba(0, 2, 40, 0.61);
  background-color: #fff;
  transition: all .3s;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.custom-dot::before {
  content: '';
  display: inline-block;

  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #000228;
}
</style>
